<template>
	<b-container fluid>
		<b-row class="mt-3" align-h="center">
			<b-col xl="4" lg="6" md="9">
				<b-card class="text-center">
					<!-- Baycho Logo -->
					<header class="px-5 pb-3">
						<b-img :src="appLogo" fluid />
					</header>

					<!-- Router View -->
					<router-view :store="store"></router-view>
				</b-card>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	props: ["store"],
	created() {
		if (this.token) {
			// redirecting to cart - place order
			this.$router.replace({ name: "CartPlaceOrder" });
			return;
		}
	},
	computed: {
		...mapGetters({
			token: "login/getToken",
			appLogo: "getAppLogo",
		}),
	},
};
</script>
